import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../util/Session';
import React from 'react';
import './Calendar.css';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Calendar as ReactBigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'

const localizer: any = momentLocalizer(moment);
const DnDCalendar: any = withDragAndDrop(ReactBigCalendar);

const eventListing: any[] = [
    {
        'title': 'All Day Event very long title',
        'allDay': true,
        'start': new Date(2019, 3, 0),
        'end': new Date(2019, 3, 1)
    },
    {
        'title': 'Long Event',
        'start': new Date(2019, 3, 7),
        'end': new Date(2019, 3, 10)
    }
]

interface IProps {

}

interface IState {
    events: any[]
}

class Calendar extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)
        
        this.state = {
            events: [
                {
                    start: new Date(),
                    // @ts-ignore
                    end: new Date(moment().add(1, "days")),
                    title: "Some title"
                }
            ]
        }
    }

    handleSelect = ({ start, end }: any) => {
        const title = window.prompt('New Event name')
        if (title)
            this.setState({
                events: [
                    ...this.state.events,
                    {
                        start,
                        end,
                        title,
                    },
                ],
            })
    }

    onEventResize = ({ event, start, end, allDay }: any) => {
        this.setState(state => {
            state.events[0].start = start;
            state.events[0].end = end;
            return { events: state.events };
        });
    };

    onEventDrop = ({ event, start, end, allDay }: any) => {
        console.log(start);
    };
    
    render() {
        return(

            <div>
        Calendar is showing

            <DnDCalendar
                    selectable
                    defaultDate={new Date()}
                    defaultView="month"
                    events={this.state.events}
                    localizer={localizer}
                    onEventDrop={this.onEventDrop}
                    onEventResize={this.onEventResize}
                    onSelectEvent={(event:any) => alert(event.title)}
                    onSelectSlot={this.handleSelect}
                    resizable
                    style={{ height: "100vh" }}

                />
            </div>
        );
    }
}

const condition = (authUser: any) => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition))(Calendar);