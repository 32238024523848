import React from 'react'; 
import './HeaderNav.css';
import { Logo3 } from '../../assets';
import { AuthUserContext } from '../../util/Session';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../util/constants/routes';
import SignOut from '../SignOut';
import * as ROLES from '../../util/constants/roles';

import {
    Heading,
    Box,
    Grid, 
    FormControl,
    FormLabel,
    Input,
    Button, 
} from "@chakra-ui/core"


const HeaderNav = () => (
    <div>
        <AuthUserContext.Consumer>
            {authUser => 
                authUser ? 
                <HeaderNavWithAuth authUser={authUser}/> 
                : <HeaderNavWithoutAuth /> }
        </AuthUserContext.Consumer>
    </div>
)

const HeaderNavWithAuth = ( { authUser } : any) => (
    <div className="header">
        <div className="Logo-border">
            <Link to={ROUTES.CALENDAR} >
                <img src={Logo3} style={{width: 200, height: "auto", minHeight: 60}} alt="logo" />
            </Link>
        </div>
        <div className="navbar-menu">
            {!!authUser && (
                <Link to={ROUTES.ADMIN}>
                    <div className="navbar-menu-item">Admin</div>
                </Link>
            )}
            <Link to={ROUTES.CALENDAR}>
                <div className="navbar-menu-item">Calendar</div>
            </Link>
            <Link to={ROUTES.CONTACTS}>
                <div className="navbar-menu-item">Contacts</div>
            </Link>
            <Link to={ROUTES.SETTLEMENTS}>
                <div className="navbar-menu-item">Settlements</div>
            </Link>
            <Link to={ROUTES.ACCOUNT}>
                <div className="navbar-menu-item">Account</div>
            </Link>
            <SignOut className="navbar-menu-item"/>
        </div>
    </div>
)

const HeaderNavWithoutAuth = () => (
    <Box >
    <div className="header">

            <Link to={ROUTES.SIGN_IN} >
                <img src={Logo3} alt="logo" style={{maxWidth: 200, height: 60}}/>
            </Link>
        <div className="navbar-menu">
                <Link to={ROUTES.SIGN_IN}>
                    <Button variantColor="blue">
                        Sign In
                    </Button>
                </Link>
        </div>
    </div>

    <ColorBar />
    </Box>
)

export default HeaderNav;



const ColorBar = () => {

    let colors =[
        "green.300",
        "orange.500",
        "red.800",
        "blue.500",
        "red.600",
        "yellow.200",
        "teal.600",
        "purple.200",
        "cyan.500",
        "pink.900",
        "yellow.400",
        "purple.400",
        "blue.500",
        "green.400",
        "orange.800",
        "red.500",
        "yellow.500",
        "teal.700",
        "purple.200",
        "cyan.400",
        "pink.800",
        "yellow.400",
        "teal.600",
        "green.300",
        "orange.500",
        "red.800",
        "blue.500",
        "red.600",
        "yellow.200",
        "teal.600",
        "purple.200",
        "cyan.500",
        "pink.900",
        "yellow.400",
        "purple.400",
        "blue.500",
        "green.400",
        "orange.800",
        "red.500",
        "yellow.500",
        "teal.700",
        "purple.200",
        "cyan.400",
        "pink.800",
        "yellow.400",
        "teal.600",
    ]


    return <Grid templateColumns="repeat(1000, minmax(40px, 1fr))" overflow="hidden">
    {
        colors.map( (el) => {
            return <Box bg={el} height="3px">

            </Box>
        })
    }

    </Grid>

}