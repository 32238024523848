import Firebase, { withFirebase } from '../../util/Firebase';
import React from 'react';
import { Logo3 } from '../../assets'
import './SignUp.css';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../util/constants/routes';
import * as ROLES from '../../util/constants/roles';
import PostgreSQL from '../../util/PostgreSQL';
import { auth } from 'firebase';
import {
    Box,
} from "@chakra-ui/core"

const SignUpPage =() => (
    <div>
        <SignUpForm />
    </div>
)

interface IProps {
    firebase: Firebase,
    history: any,
    postgresQL: PostgreSQL
}

interface IState {
    email: string,
    username: string,
    passwordOne: string,
    passwordTwo: string,
    isAdmin: boolean,
    error: any
}

const initialState: any = {
    email: '',
    username: '',
    passwordOne: '',
    passwordTwo: '',
    isAdmin: false,
    error: null 
}

class NewUserSignupFormBase extends React.Component<IProps, IState> {
    postgresQL: PostgreSQL;
    
     constructor(props: any) {
         super(props);
         this.state = {
            ...initialState
            };
         this.postgresQL = new PostgreSQL();
     }

     onSubmit = (event: any) => {
         const { username, email, passwordOne, isAdmin } = this.state;
         
         const roles: any = {};
         
         if (isAdmin) {
             roles[ROLES.ADMIN] = ROLES.ADMIN;
         }

         this.props.firebase
             .doCreateUserWithEmailAndPassword(email, passwordOne)
             .then((authUser: any) => {
                 return this.postgresQL.addUser(email,username,authUser.user.uid);
             })
             .then(response => response.json())
             .then((postgresResult: any) => {
                 console.log(postgresResult);
                 return this.props.firebase.doSendEmailVerification();
             })
             .then(() => {
                 this.setState({ ...initialState });
                 this.props.history.push(ROUTES.CALENDAR);
             })
             .catch((error: any) => {
                 console.log({error});
                 this.setState({ error });
             });
         event.preventDefault();
     }

     onChange = (event: any ) => {
         //@ts-ignore
         this.setState({ [event.target.name]: event.target.value})
     }

     onChangeCheckbox = (event: any) => {
         //@ts-ignore
         this.setState({ [event.target.name]: event.target.checked}); 
     }


    render() {
        const {
            email,
            username,
            passwordOne,
            passwordTwo,
            error
        } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email.length < 4 ||
            username.length < 4; 
            
        return <Box background="white">
            <Box p={4} bg="blue.700">
                    <img src={Logo3} alt='Solid Slate Logo'></img>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-flex-container">
                            <div className="signup-item">
                                <h2>
                                    Sign Up
                                </h2>
                                <label htmlFor="username" id="username">
                                    Username:
                                </label>
                            </div>
                            <div className="signup-item">
                                <div className="input-container">
                                    <input
                                        type="text"
                                        name="username"
                                        value={username}
                                        id="username"
                                        onChange={this.onChange}
                                        placeholder="Username" />
                                </div>
                            </div>
                                <label htmlFor="email" id="email">
                                    Email:
                                </label>
                            <div className="signup-item">
                              <div className="input-container">
                                    <input 
                                        type="email" 
                                        name="email" 
                                        value={email} 
                                        id="email" 
                                        onChange={this.onChange}
                                        placeholder="example@example.com"
                                        />
                                </div>
                            </div>
                            <div className="signup-item">
                                <label htmlFor="password">
                                    Password:
                                </label>
                            </div>
                            <div className="signup-item">
                                <div className="input-container">
                                    <input 
                                        type="password" 
                                        name="passwordOne" 
                                        id="passwordOne" 
                                        value={passwordOne} 
                                        onChange={this.onChange}
                                        placeholder="Password"
                                    />
                                </div>
                            </div>
                            <div className="signup-item">
                                <div className="input-container">
                                    <input
                                        type="password"
                                        name="passwordTwo"
                                        id="passwordTwo"
                                        value={passwordTwo}
                                        onChange={this.onChange}
                                        placeholder="Confirm Password"
                                    />
                                </div>
                            </div>

                            <div className="signup-item">
                                <button disabled={isInvalid} type="submit">
                                    Sign Up
                                </button>
                            </div>
                            {error && <p>error.message</p>}
                        </div>
                    </form>
                    </Box>
        </Box>
    }
}

const SignUpLink = () => (
    <p>
        Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </p>
);

const SignUpForm = compose(
    withRouter,
    withFirebase
    )(NewUserSignupFormBase);

export default SignUpPage;

export { NewUserSignupFormBase, SignUpLink };