import React from 'react';
import { AuthUserContext } from '../Session';
import Firebase, { withFirebase } from '../Firebase';


interface Props {
    firebase: Firebase,
    listener: any
}

interface State {
    authUser: any,
}

const withAuthentication = (Component: any) => {
    class WithAuthentication extends React.Component<Props, State> {
        listener: any;
        constructor(props: any) {
            super(props)

            this.state = {
                //@ts-ignore
                authUser: JSON.parse(localStorage.getItem('authUser'))
            }
        }

        componentDidMount() {
            this.listener = this.props.firebase.onAuthUserListener(
                (authUser: any) => {
                    localStorage.setItem('authUser', JSON.stringify(authUser));
                    this.setState({ authUser });
                },
                () => {
                    localStorage.removeItem('authUser');
                    this.setState({ authUser: null })
                }
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }
    return withFirebase(WithAuthentication);
};

export default withAuthentication