import React from 'react';
import './Settlements.css';
import { withAuthorization } from '../../util/Session';

const Settlements = () => (
    <div>
            Settlements is showing
    </div>
)

const condition = (authUser: any) => !!authUser;

export default withAuthorization(condition)(Settlements);