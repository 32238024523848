import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import PostgreSQL from '../PostgreSQL';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Firebase {
    postgresQL: PostgreSQL;
    auth: app.auth.Auth;
    
    constructor() {
        app.initializeApp(firebaseConfig);

        this.auth = app.auth();
        this.postgresQL = new PostgreSQL();
    }

    // AUTH API
    
    doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);
    
    doSignInWithEmailAndPassword = (email: string, password: string) => 
    this.auth.signInWithEmailAndPassword(email, password);
    
    doSignOut = () => this.auth.signOut();
    
    doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);
    
    doPasswordUpdate = (password: string) => this.auth.currentUser!.updatePassword(password);

    doSendEmailVerification = () => 
    //@ts-ignore
        this.auth.currentUser.sendEmailVerification({
            url: process.env.REACT_APP_FIREBASE_CONFIRMATION_EMAIL_REDIRECT,
        });

    // Merge Auth and DB User API
    onAuthUserListener = (next: (authorizedUser: any) => void, fallback: () => void) => 
        this.auth.onAuthStateChanged((authUser: any) => {
            if(authUser) {
                this.getUserFromPostgresQL(authUser.uid)
                    .then((response: Response) => {
                        let userFromPostgresQL: any = response.json();
                        return userFromPostgresQL
                    })
                    .then(postgresQLDBUser => {

                        // default empty roles
                        if (!postgresQLDBUser.roles) {
                            postgresQLDBUser.roles = {};
                        }

                        //merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...postgresQLDBUser
                        };

                        next(authUser);
                    })
            } else {
                fallback();
            }
        });

        // USER API

        getUserFromPostgresQL = (uid: string) => this.postgresQL.getUser(uid);
        
}

export default Firebase;