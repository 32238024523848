import React from 'react';
import './Contacts.css';
import { withAuthorization } from '../../util/Session';

function Contacts() {
    return <div>
        Contacts is showing
    </div>
}

const condition = (authUser: any) => !!authUser;

export default withAuthorization(condition)(Contacts);