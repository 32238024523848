import React from 'react';
import Firebase, { withFirebase } from '../../util/Firebase';
import uuidv4 from 'uuid';

interface IProps{
    firebase: Firebase
}

interface IState {
    orgName: string,
    venueName: string,
    stageName: string
    loading: boolean,
    organizations: any[],
    error: any
}

const initialState = {
    orgName: '',
    venueName: '',
    stageName:'',
    organizations: [],
    loading: false,
    error: null
}

class OrgSetup extends React.Component<IProps, IState>{
    constructor(props: any) {
        super(props)
    
        this.state = {
             ...initialState
        }
    }

    componentDidMount() {
        this.setState({loading: true})
    }

    onChange = (event: any) => {
        //@ts-ignore
        this.setState({ [event.target.name]: event.target.value });
    };



    onSubmit = (event: any) => {
        const {orgName, venueName, stageName, error} = this.state;

        // this.props.firebase
        //     .organizations()
        //     .set({
        //         orgName,
        //         venueName,
        //         stageName
        //     })
        //     .then((organization: any ) => {
        //         this.setState({organizations: organization})
        //         alert('Org set');
        //     }).catch((error: any ) => {

        //         this.setState({ error })
        //     });
        //     event.preventDefault();
    }
    
    render() {
        const { orgName, venueName, stageName, error } = this.state;

        let isInvalid: boolean = (orgName.length < 2 && venueName.length < 2 && stageName.length < 2);

        return(
            <div>
                <p>Organization Setup</p>
                <form onSubmit={this.onSubmit}>
                    <br/>
                    <label>
                        Organization Name: 
                    </label>
                    <input
                        name="orgName"
                        value={orgName}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Organization Name"
                        title="What's the name of the company that owns the venue?"
                    /> 
                    <br />
                    <label>
                        Venue Name:
                    </label>
                    <input
                        name="venueName"
                        value={venueName}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Venue Name"
                        title="What's the venue known as?"
                    /> 
                    <br />
                    <label>
                        Stage Name:
                    </label>
                    <input
                        name="stageName"
                        value={stageName}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Stage Name"
                        title="What would you like to name this stage (In case of Multi Stage Venues)"
                    /> 
                    <br />

                    <button disabled={isInvalid} type="submit">
                        Update Organization Info
                    </button>
                    {error && <p>{error.message}</p>}
                </form>
            </div>
        )
    }
}

export default withFirebase(OrgSetup);