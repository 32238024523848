import React from 'react';
import './Footer.css';
import {
    Box,
    Grid,
} from "@chakra-ui/core"


function FooterNav() {
   
    return <Box >
        <ColorBar />

    </Box>
}

export default FooterNav;

const ColorBar = () => {

    let colors =[
        "green.300",
        "orange.500",
        "red.800",
        "blue.500",
        "red.600",
        "yellow.200",
        "teal.600",
        "purple.200",
        "cyan.500",
        "pink.900",
        "yellow.400",
        "purple.400",
        "blue.500",
        "green.400",
        "orange.800",
        "red.500",
        "yellow.500",
        "teal.700",
        "purple.200",
        "cyan.400",
        "pink.800",
        "yellow.400",
        "teal.600",
        "green.300",
        "orange.500",
        "red.800",
        "blue.500",
        "red.600",
        "yellow.200",
        "teal.600",
        "purple.200",
        "cyan.500",
        "pink.900",
        "yellow.400",
        "purple.400",
        "blue.500",
        "green.400",
        "orange.800",
        "red.500",
        "yellow.500",
        "teal.700",
        "purple.200",
        "cyan.400",
        "pink.800",
        "yellow.400",
        "teal.600",
    ]


    return <Grid templateColumns="repeat(1000, minmax(40px, 1fr))" overflow="hidden">
    {
        colors.map( (el) => {
            return <Box bg={el} height="3px">

            </Box>
        }).reverse()
    }

    </Grid>

}