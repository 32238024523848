import React from 'react';
import PasswordChangeForm from '../PasswordChange';
import { PasswordResetForm } from '../PasswordReset';
import { withAuthorization, AuthUserContext, withEmailVerification } from '../../util/Session';
import { compose } from 'recompose';
import OrgSetup from '../OrgSetup';

const AccountPage = () => {
    return (
        <AuthUserContext.Consumer>
            {(authUser: any) => (
                <div>
                    <h1>Account: {authUser.email}</h1>
                    <PasswordResetForm />
                    <PasswordChangeForm />
                    <hr/>
                    <OrgSetup />
                </div>
            )}
        </AuthUserContext.Consumer>
    )
}

const condition = (authUser: any) => !!authUser;

export default compose(
    withEmailVerification,
    withAuthorization(condition)  
)(AccountPage);  