import React from 'react';
import AuthUserContext from './context';
import Firebase, { withFirebase } from '../Firebase';

const withEmailVerification = (Component: any) => {
    interface IProps {
        firebase: Firebase
    }

    interface IState {
        isSent: boolean
    }

    const needsEmailVerification = ( authUser: any) =>
        authUser &&
        !authUser.emailVerified &&
        authUser.providerData
            .map((provider: any) => provider.providerId ).includes("password")

    class WithEmailVerification extends React.Component<IProps, IState>  {
        constructor(props: any) {
            super(props)
        
            this.state = {
                 isSent: false
            }
        }
        

        onSendEmailVerification = () => {
            this.props.firebase
            .doSendEmailVerification()
            .then( () => this.setState({isSent: true}))
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    { (authUser: any) => 
                        needsEmailVerification(authUser) ? (
                            <div>
                                <p>
                                    <h1>
                                        Email verification hasn't completed yet
                                        </h1>
                                </p>
                                {this.state.isSent ? (
                                    <p>
                                        Email confirmation sent: Please check your emails (including the spam folder) for the confirmation email just sent. Refresh this page one you confirmed your email.
                                    </p>
                                ): (
                                    <p>
                                        Verify your Email: Please check your emails, including the spam folder, for an email asking for confirmation of that email, or send another confirmation email.
                                    </p>
                                )}
                                <button
                                    type="button"
                                    onClick={this.onSendEmailVerification}
                                    disabled={this.state.isSent}
                                >
                                    Send Confirmation Email
                                </button>
                            </div>
                        ) : (
                            <div>
                                <Component {...this.props} />
                            </div>
                        )
                    }
                    
                </AuthUserContext.Consumer>
            );
        }
    }
    return withFirebase(WithEmailVerification);
};

export default withEmailVerification;