import React from 'react';
import Firebase, { withFirebase } from '../../util/Firebase';
import './SignIn.css';
import { Logo3 } from '../../assets'
import { compose } from 'recompose';
import * as ROUTES from '../../util/constants/routes';
import { withRouter, Link } from 'react-router-dom';
import { PasswordResetLink } from '../PasswordReset';
import { SignUpLink } from '../SignUp';
import {
    Heading,
    Box,
    FormControl,
    Text,
    FormLabel,
    Input,
    Link as ChakraLink,
    Button,
} from "@chakra-ui/core"

const SignInPage = () => {
    return (
        <div>
            <SignInForm />
        </div>
    )
}

const initialState = {
    email: '',
    password: '',
    error: null
}

interface IProps {
    firebase: Firebase,
    history: any
}
interface IState {
    email: string,
    password: string,
    error: any
}



class SignInFormBase extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props)

        this.state = { ...initialState };
    }

    onSubmit = (event: any) => {
        const { email, password } = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...initialState });
                this.props.history.push(ROUTES.CALENDAR);
            })
            .catch(error => {
                this.setState({ error })
            });
        event.preventDefault();
    };

    onChange = (event: any) => {
        //@ts-ignore
        this.setState({ [event.target.name]: event.target.value });
    };


    render() {
        const { email, password, error } = this.state;

        const isInvalid: boolean = password.length < 4 || email.length < 4;

        return <Box bg="gray.500" p={["24px 14px", 12]}>
            <Box p={6} maxWidth="400px" margin="auto" bg="#282c34" borderWidth="2px"  rounded="sm">
                {/* <img src={ Logo3 } alt='Solid slate Logo'></img> */}
                <form onSubmit={this.onSubmit}>
                    <Box >

                        <Heading  textAlign="center">
                            Login
                        </Heading>

                        <FormControl isRequired mb={2}>
                            <FormLabel>
                                Email
                                </FormLabel>
                            <Input type="email"
                                name="email"
                                value={email}
                                id="email"
                                color="black"
                                onChange={this.onChange} />
                        </FormControl>

                        <FormControl isRequired mb={2}>
                            <FormLabel>
                                Password
                                </FormLabel>
                            <Input type="password"
                                name="password"
                                value={password}
                                id="password"
                                color="black"
                                onChange={this.onChange} />
                        </FormControl>


                        <Box >
                        <Button variantColor="blue" type="submit" mt={3} mb={6}>
                            Submit
                            </Button>
                            </Box>


                        <Box mt={1} >
                            <Text display="inline">
                                <ChakraLink >
                                    <Link to={ROUTES.SIGN_UP}>
                                        Sign Up
                                    </Link>
                                </ChakraLink>
                            </Text> <Text ml={1} mr={1} display={"inline"}>|</Text>  <Text display="inline">
                                <ChakraLink >
                                    <Link to={ROUTES.PASSWORD_RESET}>Reset Password</Link>
                                </ChakraLink>
                            </Text>



                        </Box>


                    </Box>
                    {error && <p>{error.message}</p>}
                </form>
            </Box>
        </Box>

    }
}

const SignInForm = compose(
    withRouter,
    withFirebase
)(SignInFormBase);

export default SignInPage;

export { SignInForm };

