import React from 'react';
import {Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import * as ROUTES from '../../util/constants/routes';

import HeaderNav from '../HeaderNav';
import FooterNav from '../FooterNav';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import SolidSlateCalendar from '../Calendar';
import Account from '../Account';
import Admin from '../Admin';
import PasswordResetPage from '../PasswordReset';
import { withAuthentication } from '../../util/Session';
import Settlements from '../Settlements';
import Contacts from '../Contacts';


const App = () => (
<div className="App">
  
  <BrowserRouter>
    <HeaderNav />

    <Route path={ROUTES.ACCOUNT} component={Account} />
    <Route path={ROUTES.ADMIN} component={Admin} />
    <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
    <Route path={ROUTES.SIGN_UP} component={SignUp} />
    <Route path={ROUTES.CALENDAR} component={SolidSlateCalendar} />
    <Route path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
    <Route path={ROUTES.SETTLEMENTS} component={Settlements} />
    <Route path={ROUTES.CONTACTS} component={Contacts} />


    <FooterNav />   
  </BrowserRouter>


</div>
);

export default withAuthentication(App);