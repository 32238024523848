const postgreSQLConfig = {
    hasuraAdminSecret: process.env.REACT_APP_HASURA_ADMIN_SECRET,
    endpointURL: process.env.REACT_APP_POSTGRESQL_ENDPOINT_URL
}

class PostgreSQL {
    private requestOptions: RequestInit;
    private headers: Headers;
    
    constructor() {
        this.headers = new Headers();
        this.requestOptions = {
            method: 'POST',
            headers: this.headers,
            body: null,
            redirect: 'follow'
        };
        this.headers.append("content-type", "application/json");
        this.headers.append("x-hasura-admin-secret", postgreSQLConfig.hasuraAdminSecret!);
    }

    addUser = (email: string, userName: string, firebaseUserId: string ) => {
        const graphql = JSON.stringify({
            query: `mutation MyMutation {
                    \n  __typename
                    \n  insert_users(objects: {
                        email: \"${email}\", firebase_user_id: \"${firebaseUserId}\", first_name: \"thisIsTheFirstName\", last_name: \"ThisIsTheLastName\", user_name: \"${userName}\"}) {
                        \n    returning {
                        \n      email
                        \n      firebase_user_id
                        \n      first_name
                        \n      last_name
                        \n      user_name
                        \n      uuid
                        \n
                    }
                        \n  }
                \n
            } `,
            variables: { }
            })



        this.requestOptions = { ...this.requestOptions, body: graphql}

        return fetch(process.env.REACT_APP_POSTGRESQL_ENDPOINT_URL!, this.requestOptions)
    }

    getUser = async (firebaseUid: string) => {
        let graphql = JSON.stringify({
            query: `{
                    users(where: {firebase_user_id: {_eq: "${firebaseUid}"}}) {
                        uuid
                        user_name
                        last_name
                        first_name
                        firebase_user_id
                        email
                    }
                }`
        })

        this.requestOptions = { ...this.requestOptions, body: graphql }

        return fetch(process.env.REACT_APP_POSTGRESQL_ENDPOINT_URL!, this.requestOptions);
    }

    getUsers = () => {
        let graphql = JSON.stringify({
            query: `{
                        users {
                            email
                            firebase_user_id
                            first_name
                            last_name
                            user_name
                            uuid
                        }
                }`
            })
        this.requestOptions = { ...this.requestOptions, body: graphql }

        return fetch(process.env.REACT_APP_POSTGRESQL_ENDPOINT_URL!, this.requestOptions)

    }
}

export default PostgreSQL;