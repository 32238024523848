import React from 'react';
import { compose } from 'recompose'; 
import { Switch, Route, Link } from 'react-router-dom';
import Firebase, { withFirebase } from '../../util/Firebase';
import { withAuthorization, withEmailVerification } from '../../util/Session';
import * as ROLES   from '../../util/constants/roles'
import * as ROUTES from '../../util/constants/routes';
import PostgreSQL from '../../util/PostgreSQL';

const AdminPage = () => (
    <div>
        <h1>Admin</h1>
        <p>
            The Admin Page is accessible by every signed in Admin user.
        </p>

        <Switch>
            <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
            <Route exact path={ROUTES.ADMIN} component={UserList} />
        </Switch>
    </div>
)

interface IUserListBaseProps {
    firebase: Firebase,
}

interface IUserListBaseState {
    users: any[],
    usersPostgres: any,
    loading: boolean
}

const postgresQL = new PostgreSQL();

class UserListBase extends React.Component<IUserListBaseProps, IUserListBaseState> {
    
    constructor(props:any) {
        super(props)
        
        this.state = {
            loading: false,
            users: [],
            usersPostgres: {}
        }
    }
    
    componentDidMount() {
        this.setState({ loading: true });

        postgresQL.getUsers()
        .then((response: Response) => {
                let userListJSON = response.json();
                return userListJSON
        }).then( (result: any) => {
            this.setState( {users: result.data.users, loading: false});
            console.log(this.state);
        })
    }
    
    render() {
        const { users, loading } = this.state;
        
        return (
            <div>
                <h2>Users</h2>
                {loading && <div> Loading...</div>}
                <ul>
                    {users.map( (user: any) => (
                        <li key={user.uid}>
                            <p>
                                <strong>ID:</strong> {user.uuid}
                            </p>
                            <p>
                                <strong>E-mail:</strong> {user.email}
                            </p>
                            <p>
                                <strong>Username:</strong> {user.user_name}
                            </p>
                            <p>
                                <Link to={{
                                    pathname: `${ROUTES.ADMIN}/${user.uuid}`,
                                    state: {user}
                                }}>
                                    Details
                                </Link>
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        ); 
    }
} 

interface IUserItemBaseProps {
    firebase: Firebase,
    match: any,
    user: any
}

interface IUserItemBaseState {
    loading: boolean,
    user: any
}

class UserItemBase extends React.Component<IUserItemBaseProps, IUserItemBaseState>{
    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            user: null,
            ...props.location.state,
        };
    }
    componentDidMount(){
        if(this.state.user) {
            return;
        }

        this.setState({loading: true});

        postgresQL.getUser(this.props.user.uuid)
        .then( (response: Response) => {
            let userFound = response.json();
            return userFound;
        }).then( (result: any ) => {
            console.log(result);
        }).catch(error => console.error(error))

        // this.props.firebase
        //     .user(this.props.match.params.id)
        //     .on('value', (snapshot: any) => {
        //         this.setState({
        //             user: snapshot.val(),
        //             loading: false
        //         });
        //     });
    }

    onSendPasswordResetEmail = () => {
        this.props.firebase.doPasswordReset(this.state.user.email);
    }

    render() {
        const { user, loading } = this.state;
        return (
            <div>
            <h2>User ID: ({this.props.match.params.id})</h2>
            {loading && <div> Loading...</div>}
            
                {user && (
                    <div key={user.uuid}>
                        <p>
                            <strong>ID:</strong> {user.uuid}
                        </p>
                        <p>
                            <strong>E-mail:</strong> {user.email}
                        </p>
                        <p>
                            <strong>Username:</strong> {user.user_name}
                        </p>
                        <p>
                            <button
                                type="button"
                                onClick={this.onSendPasswordResetEmail}
                            >
                                Send Password Reset
                            </button>
                        </p>
                    </div>
                )}
           
        </div>
        )
    }
}

const condition: any = (authUser: any) => 
            authUser && !!authUser;

const UserList = withFirebase(UserListBase);

const UserItem = withFirebase(UserItemBase);

export default compose( 
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
    )(AdminPage);